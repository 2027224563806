import { isFunction } from '../util'
import { makeCatalystFetchPostInit } from '../../../../CRA/src/Components/util/fetchUtility'
import $ from 'jquery'

//Minicart


var $miniCart = function () {
    return $("div#miniCart");
};

var $miniCartButtonWrapper = function () {
    return $(".mini-cart-button-wrapper");
}

var showMiniCartModal = function () {
    $miniCart().modal('show');
}

export const showMiniCart = function () {
    showMiniCartModal();
};

export const hideMiniCart = function () {
    $miniCart().modal('hide');
};

var addMiniCartClick = function () {
    $miniCart().find("a.product-remove").click(function () {
        var productCode = $(this).attr("data-code");
        fetch(removeFromCartUrl, makeCatalystFetchPostInit({ SKU: productCode }))
        .then(() => location.reload())
        .catch(() => location.reload());
    });
};

addMiniCartClick();

var updateMiniCart = function (onSuccess) {
    var miniCartUrl = $miniCart().data("url");
    $.ajax({
        type: "GET",
        url: miniCartUrl,
        success: function (data) {
            $miniCart().replaceWith(data);
            addMiniCartClick();
            isFunction(onSuccess) && onSuccess();
        }
    });
};

var updateMiniCartControl = function (onSuccess) {
    var miniCartControlUrl = $miniCartButtonWrapper().data("url");
    $.ajax({
        type: "GET",
        url: miniCartControlUrl,
        success: function (data) {
            $miniCartButtonWrapper().replaceWith(data);
            isFunction(onSuccess) && onSuccess();
        }
    });
};

export const refreshMiniCart = function () {
    updateMiniCart(updateMiniCartControl);
}

export const refreshAndShowMiniCart = function () {
    updateMiniCart(function () {
        updateMiniCartControl(showMiniCartModal)
    });
}

var messageNumber = 0;

var makeMessageId = function () {
    return "addToCartMessage" + (++messageNumber);
}

// TODO Move to own module
const BBSMinicartCore = {
    removeFromCart: function (config) {
        var { lineItemId, removeFromCartUrl, onSuccess, onFailure } = config;
        fetch(removeFromCartUrl, makeCatalystFetchPostInit({ id: lineItemId }))
        .then(resp => resp.json())
        .then(data => {
            if (!data.hasError) {
                onSuccess(data);
            } else {
                onFailure();
            }
        })
        .catch((err) => onFailure(err));
    }
};

// add to cart functionality
$(document).on("click", ".btn-add-to-cart", function (event) {
    event.preventDefault();
    var addToCartWrapper = $(this).closest(".add-to-cart-wrapper");
    var addToCartParent = $(this).closest(".add-to-cart-parent");
    var code = $(addToCartWrapper).data("code");
    var quantityInputs = $(addToCartWrapper).find(".js-variation-quantity input");
    var quantity = quantityInputs.length ? quantityInputs[0].value : 1;
    quantity = Number.isInteger(+quantity) ? quantity : 1;
    var url = $(addToCartWrapper).data("url");


    const $addToCartParent = $(addToCartParent);
    $addToCartParent.find(".add-to-cart-error").addClass("hidden");
    fetch(url, makeCatalystFetchPostInit({ code: code, quantity: quantity }))
    .then(resp => resp.json)
    .then(data => {
        if (!data.hasError) {
            updateMiniCart();
            updateMiniCartControl();
            var $imageContainer = $('.item-image', addToCartParent);
            const messageId = makeMessageId();
            var noteDiv = '<div id="' + (messageId) + '" class="alert alert-default fade show" role="alert">' +
                'Added to cart<span class="js-add-to-cart-icon"><i class="fas fa-shopping-cart" aria-hidden="true"></i></span>' + '</div>';
            $imageContainer.append(noteDiv);
            $addToCartParent.find(".add-to-cart-notification").append(noteDiv);
            $addToCartParent.find('.add-to-cart-link').blur();
            setTimeout(function () {
                $('#' + messageId).remove();
            }, 2000);
        } else {
            var $errorWrapper = $addToCartParent.find(".add-to-cart-notification");
            const unable = "There was an error adding to your cart.";
            const unableWithMessage = unable + " Message: ";
            const errorText = data.message && data.message.length && data.message.length > 0 ? unableWithMessage + data.message : unable;

            var errorDiv = '<div id="' + makeMessageId() + '"class="alert alert-danger alert-dismissable fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
                errorText +
                '</div>';
            $errorWrapper.append(errorDiv);
        }
    })
    .catch(() => location.reload())
});


$(document).on("click", ".js-coupon-button", function (event) {
    var couponCode = $(".js-coupon-code").val();
    var updateCartUrl = $(".shopping-cart-coupon-wrapper").data("updateCouponUrl");
    fetch(updateCartUrl, makeCatalystFetchPostInit({ code: couponCode }))
    .then(resp => resp.json())
    .then(data => {
        if (!data.Applied) {
            $('.js-coupon-error-message').html($('#js-coupon-adding-error').text());
            $('.js-coupon-error-message').removeClass("d-none");
        } else {
            window.location.reload(true);
        }
    })
    .catch(() => {
        $('.js-coupon-error-message').html($('#js-coupon-adding-error').text());
        $('.js-coupon-error-message').removeClass("d-none");
    })
});

$(document).on("click", "#miniCart .cart-item-delete", function (e) {
    var lineItemId = $(e.currentTarget).closest(".productItem").data("lineItemId");
    var deleteUrl = $miniCart().data("urlRemoveFromCart");
    var onSuccess = function () {
        updateMiniCart();
        updateMiniCartControl();
    };
    var onFailure = function () {
        window.alert("Could not delete.");
    };
    if (window.confirm("Delete this item from your cart?")) {
        $miniCart().modal('hide');
        BBSMinicartCore.removeFromCart({
            lineItemId,
            removeFromCartUrl: deleteUrl,
            onSuccess,
            onFailure
        });
    }
});

(function ($, minicart) {
    $(document).ready(function () {
        var updateCartUrl = $(".shopping-cart-detail-wrapper").data("updateCartUrl");

        // TODO Move to BBS Minicart Core
        var updateCart = function (lineItemId, qty) {
            fetch(updateCartUrl, makeCatalystFetchPostInit({ id: lineItemId, qty: qty }))
            .then(resp => resp.json())
            .then((data) => {
                if (!data.hasError) {
                    window.location.reload(true);
                }
            })
        };

        $(document).on("click", ".shopping-cart-detail-wrapper .removeFromCart", function () {
            if (window.confirm("Delete this item from your cart?")) {
                var lineItemId = $(this).closest(".shopping-cart-item").data("lineItemId");
                var removeFromCartUrl = $(".shopping-cart-detail-wrapper").data("removeFromCartUrl");
                var onSuccess = function () {
                    window.location.reload(true);
                }
                var config = { lineItemId, removeFromCartUrl, onSuccess };
                minicart.removeFromCart(config);
            }
        });

        $(document).on("click", ".shopping-cart-detail-wrapper .updateCart", function () {
            let $cartItem = $(this).closest(".shopping-cart-item");
            let lineItemId = $cartItem.data("lineItemId");
            let qty = $cartItem.find(".qty-option").val();
            updateCart(lineItemId, qty);
        });
    });
})($, BBSMinicartCore);
