import $ from 'jquery'

import { showMiniCart, hideMiniCart, refreshMiniCart, refreshAndShowMiniCart } from './cart';
import './skin';

import 'flickity';
import 'flickity-as-nav-for';

import tileCarousels from './carousels/tileCarousels';

//import eac_css from 'easy-autocomplete/src/sass/easy-autocomplete.scss';
import EasyAutocomplete from "easy-autocomplete";

import { formDataToBody, makeFormData } from '../../../CRA/src/Components/util/catalystFormDataBuilder';

global.jQuery = $;
window.jQuery = $;
window.$ = $;


document.addEventListener('DOMContentLoaded', () => {
    tileCarousels();
});

global["MiniCart"] = global["MiniCart"] || {}
global.MiniCart.show = showMiniCart;
global.MiniCart.hide = hideMiniCart;
global.MiniCart.refresh = refreshMiniCart;
global.MiniCart.refreshAndShow = refreshAndShowMiniCart;

global.EasyAutocomplete = EasyAutocomplete;

global.FormDataUtil = {
    makeFormData,
    formDataToBody
}
