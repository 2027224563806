$(document).ready(function () {

    // prevent scrolling when mobile menu is open
    $('.navbar-toggler').on('click', function () {
        if (!$('.navbar-collapse').hasClass('show')) {
            $('body').addClass('noscroll');
        } else {
            $('body').removeClass('noscroll');
        }
    });

    //hide initial messagae on multi registration form when tab is clicked
    $(".registration-multi .nav-link").on('click', function () {
        $(".registration-multi").append('<style>.registration-multi:after{display: none;}</style>');
    });

    $(function () {
        $('.dropdown').on({
            "click": function (event) {
                if ($(event.target).closest('.dropdown-toggle').length) {
                    $(this).data('closable', true);
                } else {
                    $(this).data('closable', false);
                }
            },
            "hide.bs.dropdown": function (event) {
                let hide = $(this).data('closable');
                $(this).data('closable', true);
                return hide;
            }
        });
    });


    if (window.sessionStorage['dismissable-banner-hide'] === 'true') {
        $('.dismissable-banner').css('height', 0);
        $('.dismissable-banner').addClass('dismissed');
        $('.dismissable-banner__container').hide();
    }

    $('.dismissable-banner__close').click(() => {
        window.sessionStorage["dismissable-banner-hide"] = "true";
        $('.dismissable-banner').css('height', 0);
        $('.dismissable-banner').addClass('dismissed');
        $('.dismissable-banner__container').hide();
    });

    // sticky header
    let header = $("#header");
    let body = $("body");
    let headerTop = header.offset().top;
    let bodyPadding = parseInt(body.css('padding-top'));

    window.onscroll = function () { updateStickyHeader() };

    function updateStickyHeader() {
        if (window.pageYOffset >= headerTop) {
            header.addClass("sticky");
            body.css('padding-top', bodyPadding + header.outerHeight());
        } else {
            header.removeClass("sticky");
            body.css('padding-top', bodyPadding);
        }
    }

    // require jquery for flickity
    var jQueryBridget = require('jquery-bridget');
    var Flickity = require('flickity');

    // make Flickity a jQuery plugin
    Flickity.setJQuery($);
    jQueryBridget('flickity', Flickity, $);
});
